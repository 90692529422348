var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('app-page-banner',{attrs:{"title":"Enquiries Exports"}}),_c('app-page',[_c('section',[(_vm.errors.length)?_c('v-alert',{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_c('p',[_vm._v("Errors:")]),_c('v-list',{attrs:{"dense":"","disabled":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.errors)+" ")])],1)],1)],1):_vm._e()],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"No exports","items":_vm.exports},scopedSlots:_vm._u([{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fromDate.toLocaleDateString("en-GB", { year: "numeric", month: "long", }))+" ")]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fromDate.toLocaleDateString("en-GB", { year: "numeric", month: "long", day: "numeric", }))+" - "+_vm._s(item.toDate.toLocaleDateString("en-GB", { year: "numeric", month: "long", day: "numeric", }))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.toLocaleDateString("en-GB"))+" ")]}},{key:"item.exportStatus",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"color":_vm.getColor(item.exportStatus),"dark":""}},[_vm._v(" "+_vm._s(item.exportStatus.toString())+" ")])],1)],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","dark":"","color":"primary","small":""},on:{"click":function($event){return _vm.download(item.id, item.fileName)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }