




































































































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "WebinarViewDetails",
  data: () => ({}),
  computed: {
    ...mapGetters("Webinar", ["webinar"]),
    ...mapState(["assetsBaseUrl"]),
  },
});
