































































































































import Vue from "vue";
import WebinarViewDetails from "@/views/webinars/WebinarView/WebinarViewDetails.vue";
import WebinarSpeakersView from "@/views/webinars/WebinarView/WebinarSpeakersView.vue";
import { mapActions, mapGetters } from "vuex";
import WebinarAgendaView from "@/views/webinars/WebinarView/WebinarAgendaView.vue";
import WebinarVideoView from "@/views/webinars/WebinarView/WebinarVideoView.vue";
import WebinarProviderConfigView from "@/views/webinars/WebinarView/WebinarProviderConfigView.vue";
import WebinarParticipantsView from "@/views/webinars/WebinarView/WebinarParticipantsView.vue";
import { loginRequest } from "@/configs/authConfig";
import { httpClientFactory } from "@/services/http/http";
import {
  WebinarsAdminClient,
  WebinarStatus,
  WebinarStatusUpdateRequest,
} from "@/clients/clients";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPage from "@/components/layout/AppPage.vue";

export default Vue.extend({
  name: "WebinarView",
  components: {
    AppPage,
    AppPageLoading,
    AppPageBanner,
    WebinarParticipantsView,
    WebinarProviderConfigView,
    WebinarVideoView,
    WebinarAgendaView,
    WebinarSpeakersView,
    WebinarViewDetails,
  },
  props: {
    id: String,
  },
  data: (): {
    loading: boolean;
    tab: number | null;
    showDeleteWarning: boolean;
  } => ({
    loading: true,
    tab: null,
    showDeleteWarning: false,
  }),
  computed: {
    ...mapGetters("Webinar", [
      "webinar",
      "providerConfig",
      "availability",
      "status",
    ]),
    isPublished(): boolean {
      return this.status == WebinarStatus.Published;
    },
  },
  async created() {
    if (!this.id) {
      await this.$router.push("/webinars");
      return;
    }
    await this.getWebinar(this.id);
    this.loading = false;
  },
  methods: {
    ...mapActions("Webinar", ["getWebinar", "reset"]),
    async deleteWebinar(): Promise<void> {
      this.showDeleteWarning = false;
      this.loading = true;
      const tokenRequest = await this.$auth.authClient.acquireTokenSilent(
        loginRequest
      );
      const accessToken = tokenRequest?.accessToken;
      const client = httpClientFactory(WebinarsAdminClient, accessToken);
      await client.deleteWebinar(this.id);
      this.reset();
      await this.$router.push("/webinars");
    },
    async updateWebinarStatus() {
      this.loading = true;
      const tokenRequest = await this.$auth.authClient.acquireTokenSilent(
        loginRequest
      );
      const accessToken = tokenRequest?.accessToken;
      const client = httpClientFactory(WebinarsAdminClient, accessToken);
      const request = new WebinarStatusUpdateRequest();
      request.id = this.id;
      request.status = this.isPublished
        ? WebinarStatus.Draft
        : WebinarStatus.Published;
      const response = await client.updateWebinarStatus(this.id, request);
      this.reset();
      await this.getWebinar(response.id);
      this.loading = false;
    },
  },
  destroyed() {
    this.reset();
  },
});
