


















































































































































































































































































































































































































































import Vue from "vue";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import {
  ApiException,
  Enquiry,
  EnquiryAddNoteRequest,
  EnquiryNote,
  EnquiriesAdminClient,
  EnquiryStatusType,
  EnquiryStatusUpdateRequest,
  EnquiryUpdateNoteRequest,
  EnquiryVerifiedUpdateRequest,
  EnquiryType,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import AppPage from "@/components/layout/AppPage.vue";
import enquiryTypeLabelMapper from "@/providers/enquiryTypeLabelMapper";

export default Vue.extend({
  name: "EnquiryView",
  components: { AppPage, AppPageBanner, AppPageLoading },
  props: {
    id: String,
  },
  data: () => ({
    loading: true,
    updating: false,
    errorMessage: "",
    enquiry: null as Enquiry | null,
    tab: null,
    newNote: null as string | null,
    updateNoteContent: null as string | null,
    updateNoteId: null as string | null,
    editNoteDialog: false,
  }),
  computed: {
    userId(): string {
      return this.$auth.activeAccount?.localAccountId ?? "";
    },
    verified(): string {
      return this.enquiry?.verified == true
        ? "Set to unverified"
        : "Set to verified";
    },
    enquiryTypes(): string {
      return (
        this.enquiry?.enquiryTypes
          ?.map((item: EnquiryType) => enquiryTypeLabelMapper(item))
          .join(", ") ?? ""
      );
    },
    enquiryStatus(): typeof EnquiryStatusType {
      return EnquiryStatusType;
    },
    brands(): string {
      return this.enquiry?.brands?.length ? this.enquiry.brands.join(", ") : "";
    },
    interests(): string {
      return this.enquiry?.interests?.length
        ? this.enquiry.interests.join(", ")
        : "";
    },
    isVerified(): string {
      return this.enquiry?.verified ? "Yes" : "No";
    },
  },
  async created() {
    this.loading = true;
    try {
      const client = await this.getClient();
      this.enquiry = await client.getEnquiry(this.id);
    } catch (e) {
      this.errorMessage = (e as ApiException).message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getClient(): Promise<EnquiriesAdminClient> {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      return this.$httpClientFactory(EnquiriesAdminClient, token.accessToken);
    },
    async toggleVerified(): Promise<void> {
      this.updating = true;
      try {
        const client = await this.getClient();
        await client.updateVerified(
          this.id,
          new EnquiryVerifiedUpdateRequest({
            verified: !this.enquiry?.verified,
          })
        );
        const enquiry = await client.getEnquiry(this.id);
        this.enquiry = Object.assign({}, enquiry);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.updating = false;
      }
    },
    async updateStatus(status: EnquiryStatusType): Promise<void> {
      this.updating = true;
      try {
        const client = await this.getClient();
        await client.updateStatus(
          this.id,
          new EnquiryStatusUpdateRequest({ enquiryStatus: status })
        );
        const enquiry = await client.getEnquiry(this.id);
        this.enquiry = Object.assign({}, enquiry);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.updating = false;
      }
    },
    async addNote(): Promise<void> {
      this.updating = true;
      try {
        const client = await this.getClient();
        await client.addNote(
          this.id,
          new EnquiryAddNoteRequest({ content: this.newNote })
        );
        const enquiry = await client.getEnquiry(this.id);
        this.enquiry = Object.assign({}, enquiry);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.updating = false;
      }
    },
    showNoteEditor(note: EnquiryNote) {
      this.updateNoteContent = note.content ?? "";
      this.updateNoteId = note.id ?? "";
      this.editNoteDialog = true;
    },
    async updateNote(): Promise<void> {
      this.updating = true;
      try {
        const client = await this.getClient();
        await client.updateNote(
          this.id,
          this.updateNoteId ?? "",
          new EnquiryUpdateNoteRequest({
            content: this.updateNoteContent ?? "",
          })
        );
        const enquiry = await client.getEnquiry(this.id);
        this.enquiry = Object.assign({}, enquiry);
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.updating = false;
        this.editNoteDialog = false;
      }
    },
    cancelUpdateNote(): void {
      this.editNoteDialog = false;
      this.updateNoteContent = null;
      this.updateNoteId = null;
    },
  },
});
