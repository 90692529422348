




















































































import Vue from "vue";
import AppPage from "@/components/layout/AppPage.vue";
import {
  ApiException,
  EnquiriesAdminClient,
  Export,
  ExportStatus,
} from "@/clients/clients";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "EnquiriesDownloadsView",
  components: { AppPageBanner, AppPage },
  data: () => ({
    headers: [
      { text: "Month", value: "fromDate" },
      { text: "Export period", value: "period", sortable: false },
      { text: "Created", value: "createdAt" },
      { text: "Status", value: "exportStatus" },
      { text: "Download", value: "download", sortable: false },
    ],
    loading: false,
    exports: [] as Export[],
    total: 0,
    errors: "",
  }),
  async created(): Promise<void> {
    this.loading = true;
    try {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(
        EnquiriesAdminClient,
        token.accessToken
      );
      const result = await client.getEnquiryExports();
      this.exports = result.exports ?? [];
      this.total = result.total ?? 0;
    } catch (e) {
      this.errors = (e as ApiException).message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getColor(status: ExportStatus): string {
      switch (status) {
        case ExportStatus.Complete:
          return "success";
        case ExportStatus.Failed:
          return "error";
        default:
          return "info";
      }
    },
    async download(id: string, fileName?: string | null | undefined) {
      this.loading = true;
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          EnquiriesAdminClient,
          token.accessToken
        );
        const result = await client.getEnquiryExport(id);
        let link = document.createElement("a");
        link.download = fileName ?? "download.csv";
        link.href = URL.createObjectURL(result.data);
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
      } catch (e) {
        this.errors = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
