
























import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "WebinarVideoView",
  data: () => ({}),
  computed: {
    ...mapGetters("Webinar", ["webinar"]),
    videoUri(): string {
      return this.webinar?.onDemandVideoUri ?? "";
    },
    videoContent(): string {
      return this.webinar?.onDemandVideoOembedHtml ?? "";
    },
  },
});
