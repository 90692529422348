











































import Vue from "vue";
import { mapGetters } from "vuex";
import { Agenda } from "@/clients/clients";

export default Vue.extend({
  name: "WebinarAgendaView",
  data: () => ({}),
  computed: {
    ...mapGetters("Webinar", { webinarAgenda: "agendas", webinar: "webinar" }),
    agendas(): Agenda[] {
      return this.webinarAgenda;
    },
  },
});
