















import Vue from "vue";
import { mapGetters } from "vuex";
import { WebinarParticipantAdminResponse } from "@/clients/clients";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "WebinarParticipantsView",
  data: () => ({}),
  computed: {
    ...mapGetters("Webinar", { webinarParticipants: "participants" }),
    participants(): WebinarParticipantAdminResponse[] {
      return this.webinarParticipants;
    },
    headers(): DataTableHeader[] {
      return [
        { text: "First name", align: "start", value: "firstName" },
        { text: "Last name", align: "start", value: "lastName" },
        { text: "Email", align: "start", value: "email" },
        { text: "Registration", align: "start", value: "registration" },
        { text: "Status", align: "start", value: "status" },
        { text: "Last updated", align: "start", value: "updatedAt" },
        { text: "Update", value: "update", sortable: false },
      ];
    },
  },
});
