

































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { SpeakerAdminResponse } from "@/clients/clients";

export default Vue.extend({
  name: "WebinarSpeakersView",
  data: () => ({
    imageHolder: `${process.env.VUE_APP_ASSETS_BASE_URL}/images/webinars/speakers/blank-profile-picture-g95ac279a6_640.png`,
  }),
  computed: {
    ...mapGetters("Webinar", {
      webinarSpeakers: "speakers",
      webinar: "webinar",
    }),
    ...mapState(["assetsBaseUrl"]),
    speakers(): SpeakerAdminResponse[] {
      return this.webinarSpeakers;
    },
  },
  methods: {
    fullName(index: number): string {
      return [
        this.speakers[index]?.title ?? "",
        this.speakers[index]?.fullName ?? "",
        this.speakers[index]?.postNominalLetters ?? "",
      ]
        .filter((p) => p.length > 0)
        .map((p) => p)
        .join(" ");
    },
  },
});
