

































































import Vue from "vue";
import { mapGetters } from "vuex";
import { WebinarProviderConfigAdminResponse } from "@/clients/clients";

export default Vue.extend({
  name: "WebinarProviderConfigView",
  data: () => ({
    showPassword: false,
  }),
  computed: {
    ...mapGetters("Webinar", ["webinar"]),
    providerConfig(): WebinarProviderConfigAdminResponse | null {
      return this.webinar?.providerConfig ?? null;
    },
  },
});
